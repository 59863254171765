.token {
  --whitespace-after-color: var(--text-secondary);
  --bracket-color: var(--interactive-destructive);
}

.token.whitespace {
  position: relative;
}

.token.whitespace::after {
  position: absolute;
  left: 0;
  top: 0;
  color: var(--whitespace-after-color);
  content: "·";
}

.token.bracket {
  color: var(--bracket-color);
}
